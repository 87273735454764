import React from "react";
// import SalesForceChat from "salesforce-library";
// import { getConfig } from "../utils/ConfigProvider";

// TODO - Implement chat widget
const SalesforceWidget = () => {
  // const { config } = getConfig();
  {
    /*  <SalesForceChat
        salesforceKey={config.SALESFORCE_KEY}
       /> */
  }
  return <div>Salesforce Chat Widget</div>;
};

export default SalesforceWidget;
