/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React, { useState, useContext, useEffect } from "react";
import intl from "react-intl-universal";
import { withRouter } from "react-router-dom";
import { page } from "@zilker/store-components";
import { getConfig } from "@zilker/store-components/src/utils/ConfigProvider";
import { MainContext } from "../contexts/MainContext";
import { postErrorService, openSalesforceChat } from "../utils/helpers";

import "./ErrorPage.less";
import "../theme/sharedClasses.less";

import errorImage from "../images/site-images/system-error.jpg";

function ErrorPage(props) {
  let displayMsg = intl.get("no-error-response");
  const [readMore, setReadMore] = useState(false);
  const { config } = getConfig();
  const { history } = props;

  const {
    auth: { guestTokenError }
  } = useContext(MainContext);

  useEffect(() => {
    page();
  }, []);

  useEffect(() => {
    return function preventNavigatingAway() {
      if (guestTokenError) {
        history.push("/maintenance");
      }
    };
  }, [guestTokenError]);

  const {
    location: { state }
  } = props;

  if (state && state.error) {
    // print error in the console
    console.error(state.error);
    const { error } = state;
    const { e, errIn } = error;
    const body = { msg: e.message, status: e.status, errIn };
    postErrorService(body);
    const time = new Date();
    displayMsg = `${
      error.errIn
    } - ${time.toDateString()}, ${time.toTimeString()}`;
  }

  return (
    <div className="outerBox">
      <div className="messageBox">
        <img src={errorImage} alt="Something went wrong" />
        <h1
          className={`view-title-maintenance view-title ${
            state && state.renderIEMessage ? "ie-title-width" : ""
          }`}
        >
          {state && state.renderIEMessage
            ? intl.get("not-supported-browser")
            : intl.get("something-went-wrong-message")}
        </h1>
        {state && state.error && state.error.renderIEMessage ? (
          <>
            <h3 className="ie-title-width">{intl.get("ie-browser-message")}</h3>
            <ul>
              {config.browserLinks.map(link => {
                return (
                  <li key={`${link.name}:${link.value}`}>
                    <a
                      href={link.value}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {link.name}
                    </a>
                  </li>
                );
              })}
            </ul>
          </>
        ) : (
          <>
            {state && state.error && state.error.errorCode ? (
              <>
                <h4>
                  {intl.get("error-code")} {state.error.errorCode}
                </h4>
                <h5>
                  {intl.get("zero-branches-error-message")}
                  <button
                    type="button"
                    className="support-btn"
                    onClick={openSalesforceChat}
                  >
                    {intl.get("support")}
                  </button>
                </h5>
              </>
            ) : (
              <h3>{intl.get("try-again-message")}</h3>
            )}
            <p className="email-label">{intl.get("email")}</p>
            <p>{config.supportEmail}</p>
            <div className="errorBox">
              {!readMore ? (
                <button
                  type="button"
                  className="dast-btn dast-btn-primary"
                  onClick={() => setReadMore(!readMore)}
                >
                  Read more
                </button>
              ) : (
                <p className="errorMessage">{displayMsg}</p>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default withRouter(ErrorPage);
