import React, { useState, useEffect } from "react";
import intl from "react-intl-universal";
import { BrProps } from "@bloomreach/react-sdk";
import { page, getConfig } from "@zilker/store-components";
import {
  listStatements,
  getAccountStatement
} from "../services/connectServices";
import {
  checkTokensExpired,
  pushToMaintenace,
  openSalesforceChat
} from "../utils/helpers";

import "./AccountStatementPage.less";

// Types

interface Statement {
  month: string;
  year: string;
  originalDate: string;
}
interface AccountStatement {
  cardClicked: string;
  yearSelected: string;
  statementList: Statement[];
}

interface AccountStatementPageProps extends BrProps {
  history: any;
  auth: any;
  user: any;
  account: any;
}

const AccountStatementPage: React.FC<AccountStatementPageProps> = ({
  history,
  auth,
  user,
  account
}) => {
  // Page constants
  const { config } = getConfig();
  const { supportEmail } = config;
  const initialYear = new Date().getUTCFullYear().toString();

  const pageTitle: string = intl.get("monthly-statements");

  // Initial state object
  const statementObject: AccountStatement = {
    cardClicked: "",
    yearSelected: initialYear,
    statementList: []
  };

  const months = [
    intl.get("january"),
    intl.get("february"),
    intl.get("march"),
    intl.get("april"),
    intl.get("may"),
    intl.get("june"),
    intl.get("july"),
    intl.get("august"),
    intl.get("september"),
    intl.get("october"),
    intl.get("november"),
    intl.get("december")
  ];

  // Define state
  const [accountStatement, setAccountStatement] = useState<AccountStatement>(
    statementObject
  );
  const [noStatementError, setNoStatementError] = useState<string>("");
  const [statementYears, setStatementYears] = useState<Array<string>>([
    initialYear
  ]);

  const [loader, setLoader] = useState<boolean>(false);
  const [noStatementsMessage, setNoStatementsMessage] = useState<boolean>(
    false
  );

  // Functions

  /**
   * ## shouldStatementBeDisplayed
   *
   * @param statement Statement
   * @param selectedYear string
   *
   * @remarks Check if the Statement should be displayed or not.
   * If the selected year comes before the current year, display statement
   * regardless of the month.
   *
   * If the selected year is the same as current year, display only past statements:
   * for the current month and for the months before the current.
   * This way we have a guard against displaying statements for the future months.
   *
   * @returns boolean
   */
  function shouldStatementBeDisplayed(
    statement: Statement,
    selectedYear: string
  ): boolean {
    let result: boolean = false;

    const selectedYearNum: number = parseInt(`${selectedYear}`, 10);

    const currentYear: number = new Date().getUTCFullYear();

    if (selectedYearNum < currentYear) {
      result = true;
    } else if (selectedYearNum === currentYear) {
      const currentMonthIndex: number = new Date().getUTCMonth();

      const monthsBeforeCurrent = months.slice(0, currentMonthIndex + 1);

      if (monthsBeforeCurrent.includes(statement.month)) {
        result = true;
      }
    }

    return result;
  }

  /**
   * ## onYearChange
   *
   * @param event
   *
   * @remarks This function is triggered when user selects a year
   * from the dropdown menu. The selected year is saved to state.
   *
   * @returns void
   */
  function onYearChange(event): void {
    const year: string = event.target.value;

    setAccountStatement(prevState => ({
      ...prevState,
      yearSelected: year
    }));

    setNoStatementError("");
  }

  function fetchAccountStatement() {
    const {
      accountDetails: { customerNumber }
    } = account;

    const statementDate = accountStatement.cardClicked;

    getAccountStatement(customerNumber, statementDate)
      .then(statement => {
        const url = window.URL.createObjectURL(new Blob([statement.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${accountStatement.cardClicked}${accountStatement.yearSelected}.pdf`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch(e => {
        if (e.response && e.response.status === 400) {
          setNoStatementError(intl.get("statement-error"));
        } else if (checkTokensExpired(e)) {
          auth.logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn:
                "Logout => fetchAccountStatement => AccountStatementPage.tsx"
            })
          );
        } else {
          pushToMaintenace(history, {
            e,
            errIn: "fetchAccountStatement => AccountStatementPage.tsx"
          });
        }
      });

    setAccountStatement(prevState => ({
      ...prevState,
      cardClicked: ""
    }));
  }

  /**
   * ## onCardClick
   *
   * @param event
   *
   * @remarks This function is triggered on card click.
   * Each card is clickable and will call a different service
   * to display the statement pdf.
   *
   * @returns void
   */
  function onCardClick(event): void {
    const card = event.currentTarget;

    setAccountStatement(prevState => ({
      ...prevState,
      cardClicked: card.name
    }));
    setNoStatementError("");
  }

  /**
   * ## parseStatementList
   * @param list Array<string> as ["20200630","20200531","20200430",...] // yyyymmdd
   * @description Transform response from DCS to list of objects.
   */
  function parseStatementList(list: Array<string>): Array<any> {
    if (!list || (list && !list.length)) {
      return [];
    }

    return list
      .map((statementDate: string) => {
        const year = statementDate.slice(0, 4);
        const month = parseInt(statementDate.slice(4, 6), 10);
        const monthName = months[month - 1];

        return {
          month: monthName,
          year,
          originalDate: statementDate
        };
      })
      .filter(
        (statementItem: Statement, index: number, self: Array<Statement>) => {
          return (
            index ===
            self.findIndex(st => st.originalDate === statementItem.originalDate)
          );
        }
      );
  }

  /**
   * ## fetchStatementList
   * @description Call Daikin service to get the list of statements. Parse the list by
   * the dates in dropdown. Set the list in the state.
   */
  async function fetchStatementList(): Promise<void> {
    const {
      accountDetails: { customerNumber }
    } = account;
    setLoader(true);
    setNoStatementsMessage(false);

    try {
      const { data }: { data: Array<string> } = await listStatements(
        customerNumber
      );
      setLoader(false);

      if (!data || (data && !data.length)) {
        setNoStatementsMessage(true);
        return;
      }
      // Update the years dropdowwn with all available years - remove duplicates using Set.
      const yearList = [...new Set(data.map(item => item.slice(0, 4)))];
      setStatementYears(yearList);

      const statementList = parseStatementList(data);
      setAccountStatement(prevState => ({
        ...prevState,
        statementList,
        yearSelected: yearList[0]
      }));
    } catch (error) {
      setLoader(false);
      setNoStatementsMessage(true);

      if (checkTokensExpired(error)) {
        auth.logout().catch(err =>
          pushToMaintenace(history, {
            e: err,
            errIn: "Logout => fetchStatementList => AccountStatementPage.tsx"
          })
        );
      } else {
        pushToMaintenace(history, {
          e: error,
          errIn: "fetchStatementList => AccountStatementPage.tsx"
        });
      }
    }
  }

  /**
   * ## filterStatementsBySelectedYear
   * @description Filter statements by selected year from dropdown and prevent future months from displaying.
   */
  function filterStatementsBySelectedYear(): Array<Statement> {
    return accountStatement.statementList
      .filter(
        (statement: Statement) =>
          statement.year === accountStatement.yearSelected
      )
      .filter((statement: Statement) =>
        shouldStatementBeDisplayed(statement, accountStatement.yearSelected)
      );
  }

  /**
   * ## renderFilteredStatements
   * @description Render filtered statements.
   */
  function renderFilteredStatements(): any {
    const statementsBySelectedYear: Array<
      Statement
    > = filterStatementsBySelectedYear();

    if (statementsBySelectedYear.length) {
      return statementsBySelectedYear.map((statement: Statement) => {
        return (
          <button
            type="button"
            aria-label={`account-statement-${statement.month}`}
            className="account-statement-card dast-btn dast-btn-primary"
            key={`account-statement-${statement.month}`}
            onClick={onCardClick}
            name={statement.originalDate}
          >
            <i className="icon-PDF" />
            <p>{statement.month.toUpperCase()}</p>
          </button>
        );
      });
    }

    return "";
  }

  useEffect(() => {
    if (accountStatement.cardClicked !== "") {
      fetchAccountStatement();
    }
  }, [accountStatement.cardClicked]);

  useEffect(() => {
    page();

    fetchStatementList();
  }, []);

  return (
    <div className="account-statement-container content-box">
      <h2>{pageTitle}</h2>
      <select onChange={onYearChange} aria-label="change year">
        {statementYears.map((year: string) => {
          return (
            <option value={year} key={`account-statement-${year}`}>
              {year}
            </option>
          );
        })}
      </select>
      <p className="statement-error">{noStatementError}</p>
      {/* eslint-disable-next-line no-nested-ternary */}
      {accountStatement.statementList.length ? (
        renderFilteredStatements() ? (
          <div className="account-statement-cards grid">
            {renderFilteredStatements()}
          </div>
        ) : (
          <p className="statement-error">
            {intl.get("no-statements-available")}
          </p>
        )
      ) : (
        ""
      )}
      {noStatementsMessage && (
        <p className="statement-warning">
          {intl.get("no-account-statements")}
          <a
            className="contact-email dast-link"
            href={`mailto:${supportEmail}`}
          >
            {supportEmail}
          </a>
          {intl.get("or")}
          <button
            type="button"
            onClick={openSalesforceChat}
            className="chat-launcher dast-link"
          >
            {intl.get("chat-us")}
          </button>
        </p>
      )}
      {loader && <div className="loader" />}
    </div>
  );
};

export default AccountStatementPage;
