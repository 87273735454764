import React, { FC, useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import Modal from "react-responsive-modal";
import intl from "react-intl-universal";
import { History } from "history";
import {
  SavedOrderDetailComponent,
  page,
  productAdded,
  productViewed,
  getConfig,
  cortexFetch,
  zoom,
  AlternateBranchList,
  Messagecontainer
} from "@zilker/store-components";
import {
  getAvailability,
  checkEntitlementGQL
} from "@elasticpath/ref-store/src/services/connectGQLservices";
import { EntitlementDetails } from "./PartsFinderPage";
import {
  checkTokensExpired,
  checkResponse,
  pushToMaintenace,
  generateSpecificErrorMessage,
  groupCartItems,
  InventoryAvailabilityInterface,
  isSpecialAirPurifier,
  updateBranchNumber,
  formatQuoteAvailability,
  openSalesforceChat
} from "../utils/helpers";
import { MainContext } from "../contexts/MainContext";

import { addToCart, updateAhriSystemGroupItems } from "../services/EpServices";
import "./SavedOrderDetails.less";

type summaryInfo = {
  amount: number;
  currency: string;
  display: string;
};

interface SavedOrderDetailsProps {
  orderName: string;
  link: string;
  history: History;
  logout: any;
}

interface CartDetailInterface {
  self: { type: string; uri: string; href: string };
  messages: [];
  _item: any[];
  _price: any[];
  _total: any[];
  configuration: {};
  quantity: number;
  branchAvailability?: number;
  regionAvailabiliy?: number;
}

interface OrderSummaryInterface {
  subTotal: summaryInfo;
  total: summaryInfo;
  tax: summaryInfo;
}

const SavedOrderDetails: FC<SavedOrderDetailsProps> = ({
  orderName,
  link,
  history,
  logout
}) => {
  const [cartData, setCartData] = useState<CartDetailInterface[]>([]);
  const [orderSummary, setOrderSummary] = useState<OrderSummaryInterface>();
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingPickupOrDelivery, setLoadingPickupOrDelivery] = useState<
    string
  >(null);
  const [qtyChangeDisabled, setQtyChangeDisabled] = useState<boolean>(false);
  const [removeItemDisabled, setRemoveItemDisabled] = useState<boolean>(false);
  const [savedOrder, setSavedOrder] = useState<any>(null);
  const [invalidSku, setInvalidSku] = useState<string>("");
  const [invalidSkuError, setInvalidSkuError] = useState<string>("");
  const [groupedItems, setGroupedItems] = useState<any>({});
  const [activeGroups, setActiveGroups] = useState<Array<string>>([]);
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [branch, setBranch] = useState<{ code: number; vendor: string }>(null);
  const [inventoryError, setInventoryError] = useState<string>("");
  const [isProductBeingAdded, setIsProductBeingAdded] = useState({
    sku: "",
    shippingMethod: ""
  });
  const [entitlements, setEntitlements] = useState<EntitlementDetails[]>(null);

  const context = useContext<{
    cart: any;
    user: any;
    branches: any;
    account: any;
  }>(MainContext);
  const {
    cart: {
      setSuccesCartPopupMessage,
      setErrorCartPopupMessage,
      getCartDetails,
      cartDetails: { defaultCart },
      checkCartWarning
    },
    account: {
      accountDetails: { customerNumber, customerRoles, homeBranch }
    },
    branches: {
      branchesList,
      airPurifierBranch: { branchNumber: airPurifierBranchNumber }
    }
  } = context;
  const selectedBranch = defaultCart ? defaultCart.selectedBranch : null;
  const selectedBranchNumber = selectedBranch && selectedBranch.code;
  const BRANCHES_VIRTUAL = intl.get("virtual-branches");
  const isVirtualBranchUser =
    customerRoles && customerRoles.includes(BRANCHES_VIRTUAL);

  const { config } = getConfig();
  const { defaultChannel } = config.brXM;
  const isMotili = defaultChannel === "motili";

  const hasSpecialAirPurifiers =
    cartData &&
    Boolean(
      cartData.find(({ _item }) => isSpecialAirPurifier(_item[0]._code[0].code))
    );

  let addToDefaultCartLink;
  if (savedOrder) {
    const { _addcarttodefaultcartform } = savedOrder;
    addToDefaultCartLink = _addcarttodefaultcartform[0].links[0].uri;
  }

  const addCartToDefaultCart = (shippingMethod: "pickup" | "delivery") => {
    const skus = cartData.map(item => {
      return item._item[0]._code[0].code;
    });
    return cortexFetch(addToDefaultCartLink, {
      method: "post",
      body: JSON.stringify({
        "shipping-method": shippingMethod,
        "branch-number": updateBranchNumber(
          shippingMethod === "delivery",
          skus,
          airPurifierBranchNumber,
          homeBranch,
          selectedBranchNumber
        )
      })
    })
      .then(res => {
        const onSuccess = data => data;
        const onError = data => {
          if (!data.ok) {
            return data.json().then(json => {
              throw json;
            });
          }
          throw data;
        };
        return checkResponse(res, onSuccess, onError);
      })
      .catch(err => {
        throw err;
      });
  };

  const addItemToDefaultCart = (
    foundItem: CartDetailInterface[],
    shippingMethod: "pickup" | "delivery"
  ) => {
    const items = foundItem.map(item => {
      const itemCode = item._item[0]._code[0].code;
      const ahriNum = item["ahri-number"];
      return {
        code: itemCode,
        quantity: item.quantity,
        "branch-number": updateBranchNumber(
          shippingMethod === "delivery",
          itemCode,
          airPurifierBranchNumber,
          homeBranch,
          selectedBranchNumber
        ),
        "shipping-method": shippingMethod,
        ...(ahriNum && { "ahri-number": ahriNum })
      };
    });

    const { addItemsToCart } = defaultCart;
    return addToCart(addItemsToCart.self.uri, { items });
  };

  const handleAddToCart = (shippingMethod: "pickup" | "delivery") => {
    setLoadingPickupOrDelivery(shippingMethod);

    const analyticsItems = cartData
      .map(element => {
        const isEntitled = entitlements.find(
          prod => prod.sku === element._item[0]._code[0].code
        ).entitled;
        if (isEntitled) {
          return {
            id: element._item[0]._code[0].code,
            quantity: element.quantity,
            category: element._item[0]._definition[0].details.find(
              detail => detail.name.toLowerCase() === "product_category"
            ).value,
            name: element._item[0]._definition[0]["display-name"],
            brand: element._item[0]._definition[0].details.find(
              detail => detail.name === "brand"
            ).value,
            price: element._price[0]["list-price"][0].amount
          };
        }
        return undefined;
      })
      .filter(item => item);

    const itemQuantity = analyticsItems.reduce((acc, item) => {
      return acc + item.quantity;
    }, 0);

    addCartToDefaultCart(shippingMethod)
      .then(() => {
        // sends information to Segment for every product user adds
        analyticsItems.forEach(item =>
          productAdded(
            item.name,
            item.id,
            item.price,
            item.brand,
            item.category,
            item.quantity
          )
        );
      })
      .then(() => {
        return getCartDetails();
      })
      .then(() => {
        setLoadingPickupOrDelivery("");
        setSuccesCartPopupMessage(itemQuantity);
      })
      .catch(e => {
        setLoadingPickupOrDelivery("");

        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => handleAddToCart => SavedOrderDetails.tsx"
            })
          );
        } else {
          setErrorCartPopupMessage(generateSpecificErrorMessage(e, orderName));
        }
      });
  };

  const handleAddSingleItemTypeToCart = (
    sku: string,
    shippingMethod: "pickup" | "delivery",
    groupId?: string
  ) => {
    setIsProductBeingAdded({ sku, shippingMethod });

    const foundItem = cartData.filter(
      item => item._item[0]._code[0].code === sku
    );

    const isAHRI = groupId && groupId.includes("ahri");
    const ahriNumber = isAHRI
      ? groupId.split("ahri-group-")[1]
      : foundItem[0]["ahri-number"];

    const foundItems =
      ahriNumber && cartData.filter(item => item["ahri-number"] === ahriNumber);

    if (foundItem.length > 0) {
      const foundItemArrayUsed = !foundItems ? foundItem : foundItems;
      const analyticsItems = foundItemArrayUsed.map(item => {
        return {
          id: item._item[0]._code[0].code,
          quantity: item.quantity,
          category: item._item[0]._definition[0].details.find(
            detail => detail.name.toLowerCase() === "product_category"
          ).value,
          name: item._item[0]._definition[0]["display-name"],
          brand: item._item[0]._definition[0].details.find(
            detail => detail.name === "brand"
          ).value,
          price:
            item._price && item._price[0]
              ? item._price[0]["list-price"][0].amount
              : item._item[0]._price[0]["list-price"][0].amount
        };
      });

      const itemQuantity = !foundItems
        ? foundItem[0].quantity
        : foundItems.reduce(
            (total, current) => total + Number(current.quantity),
            0
          );
      const itemArr = foundItems || foundItem;
      addItemToDefaultCart(itemArr, shippingMethod)
        .then(() => {
          // sends information to Segment
          analyticsItems.forEach(analyticsItem => {
            productViewed(
              analyticsItem.name,
              analyticsItem.id,
              analyticsItem.price,
              analyticsItem.brand,
              analyticsItem.category
            );
            productAdded(
              analyticsItem.name,
              analyticsItem.id,
              analyticsItem.price,
              analyticsItem.brand,
              analyticsItem.category,
              analyticsItem.quantity
            );
          });
        })
        .then(() => {
          return getCartDetails();
        })
        .then(() => {
          setIsProductBeingAdded({ sku: "", shippingMethod: "" });
          setSuccesCartPopupMessage(itemQuantity);
        })
        .catch(e => {
          setIsProductBeingAdded({ sku: "", shippingMethod: "" });
          if (checkTokensExpired(e)) {
            logout().catch(err =>
              pushToMaintenace(history, {
                e: err,
                errIn:
                  "Logout => handleAddSingleItemTypeToCart => SavedOrderDetails.tsx"
              })
            );
          } else {
            setErrorCartPopupMessage(
              generateSpecificErrorMessage(e, orderName)
            );
          }
        });
    }
  };

  const disableChangesFromItem = (val: boolean): void => {
    setQtyChangeDisabled(!!invalidSku || val);
    setRemoveItemDisabled(val);
  };

  /**
   * @description Function to retrieve the single saved order details, using the order ID
   * ( part of the link variable). Saved order details include order name, total, order items -
   *  with price and quantity.
   */
  const getSavedOrderDetails = () => {
    return cortexFetch(
      `/${link}?zoom=${zoom.zoomFetchSavedOrder.sort().join()}`
    )
      .then(res => checkResponse(res))
      .then(savedOrderResponse => {
        setSavedOrder(checkCartWarning(savedOrderResponse));
      })
      .catch(err => {
        if (checkTokensExpired(err)) {
          logout().catch(logoutErr =>
            pushToMaintenace(history, {
              e: logoutErr,
              errIn: "Logout => getSavedOrderDetails => SavedOrderDetails.tsx"
            })
          );
        } else {
          console.error(err);
          pushToMaintenace(history, {
            e: err,
            errIn: "getSavedOrderDetails => SavedOrderDetails.tsx"
          });
        }
      });
  };

  const fetchAvailability = async (orderItems, currentBranch) => {
    if (branchesList && orderItems) {
      const skusArr = orderItems.map(({ _item }) => _item[0]._code[0].code);

      getAvailability(skusArr, customerNumber, currentBranch.code)
        .then(res => {
          if (
            res &&
            res.data &&
            res.data.data &&
            res.data.data.inventory &&
            res.data.data.inventory.entitledInventory
          ) {
            const availabilityData = res.data.data.inventory.entitledInventory;
            const inventoryAvailability: InventoryAvailabilityInterface[] = formatQuoteAvailability(
              availabilityData.branches,
              availabilityData.regionRollups,
              skusArr,
              availabilityData.hubInventory
            );
            if (inventoryAvailability) {
              const itemsWithAvailability = orderItems.map(item => {
                const inventoryItem = inventoryAvailability.find(
                  ia =>
                    ia.sku === item._item[0]._code[0].code &&
                    ia.branchNumber === currentBranch.code
                );
                return inventoryItem
                  ? {
                      ...item,
                      branchAvailability: inventoryItem.branchAvailability,
                      regionAvailability: inventoryItem.regionAvailability,
                      dcAvailability: inventoryItem.dcQtyAvailable
                    }
                  : {
                      ...item,
                      branchAvailability: 0,
                      regionAvailability: 0,
                      dcAvailability: 0
                    };
              });
              setCartData(itemsWithAvailability);
              setGroupedItems(groupCartItems(itemsWithAvailability));
            }
            setInventoryError("");
          }
        })
        .catch(e => {
          console.error("Inventory error", e);
          setInventoryError(intl.get("inventory-error"));
          if (checkTokensExpired(e)) {
            logout().catch(err =>
              pushToMaintenace(history, {
                e: err,
                errIn: "Logout => fetchAvailability => SavedOrderDetails.tsx"
              })
            );
          }
        });
    }
  };

  const populateSavedOrderData = () => {
    let subTotal;
    let total;
    let tax;
    let errorMessage = "";
    const errPriceObj = {
      display: intl.get("pending"),
      amount: intl.get("pending"),
      currency: ""
    };
    // If messages[0] exists that means there is some pricing error and we will display error message and "pending" in that case
    if (
      savedOrder._total &&
      savedOrder._total[0].messages &&
      savedOrder._total[0].messages[0] &&
      savedOrder._total[0].messages[0].type === "error"
    ) {
      subTotal = errPriceObj;
      total = errPriceObj;
      tax = errPriceObj;
      const { sku } = savedOrder._total[0].messages[0].data;
      setInvalidSku(sku);
      setQtyChangeDisabled(true);
      errorMessage = intl.get("invalid-sku-error-message", {
        sku
      });
    } else {
      try {
        [subTotal] = savedOrder._total[0].cost;
        [total] = savedOrder._order[0]._total[0].cost;
        tax = savedOrder._order[0]._tax[0].total;
      } catch (e) {
        console.error(e);
        subTotal = errPriceObj;
        total = errPriceObj;
        tax = errPriceObj;
      }
      setInvalidSku("");
      setQtyChangeDisabled(false);
    }
    setOrderSummary({ subTotal, total, tax });
    setCartData(savedOrder._lineitems[0]._element);
    const groupedProsItems = groupCartItems(savedOrder._lineitems[0]._element);
    setGroupedItems(groupedProsItems);
    setActiveGroups(Object.keys(groupedProsItems));
    setLoading(false);
    setInvalidSkuError(errorMessage);
    fetchAvailability(savedOrder._lineitems[0]._element, selectedBranch);
    setBranch(selectedBranch);
  };

  const cartId = link.split("/")[2];
  const handleGroupItemsUpdate = (
    groupId: string,
    quantity: string,
    setLoader: any,
    shippingMethod: "pickup" | "delivery",
    branchNumber: string
  ) => {
    const groupItems = groupedItems[groupId];
    updateAhriSystemGroupItems(
      groupItems,
      quantity,
      cartId,
      shippingMethod,
      branchNumber
    )
      .then(res => {
        const onSuccess = data => data;
        return checkResponse(res, onSuccess);
      })
      .then(() => {
        return getSavedOrderDetails();
      })
      .then(() => {
        setLoader(false);
        disableChangesFromItem(false);
      })
      .catch(e => {
        setLoader(false);
        disableChangesFromItem(false);
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => handleGroupItemsUpdate => SavedOrderDetails.tsx"
            })
          );
        } else {
          pushToMaintenace(history, {
            e,
            errIn: "handleGroupItemsUpdate => SavedOrderDetails.tsx"
          });
        }
      });
  };

  useEffect(() => {
    page();
    getSavedOrderDetails();
  }, []);

  useEffect(() => {
    if (
      (savedOrder && (cartData && !cartData.length)) ||
      (branch && branch.code !== selectedBranch.code)
    ) {
      populateSavedOrderData();
    }
  });

  useEffect(() => {
    if (savedOrder) {
      populateSavedOrderData();
    }
  }, [savedOrder]);

  useEffect(() => {
    if (!invalidSku) setQtyChangeDisabled(false);
  }, [invalidSku]);

  useEffect(() => {
    if (branchesList && cartData && cartData.length && branch) {
      fetchAvailability(cartData, branch);
    }
  }, [branchesList]);

  const validateEntitlement = async () => {
    if (config.entitlementCheck && selectedBranch && selectedBranch.vendor) {
      const skus = cartData.map(item => item._item[0]._code[0].code);
      if (skus && skus.length) {
        try {
          const { data } = await checkEntitlementGQL(customerNumber, skus);
          if (
            data &&
            data.data &&
            data.data.customer &&
            data.data.customer.productEntitlements
          ) {
            if (!data.data.customer.productEntitlements) {
              setEntitlements([]);
            } else {
              setEntitlements(data.data.customer.productEntitlements);
            }
          }
        } catch (e) {
          console.error(e);
          setEntitlements([]);
        }
      }
    }
  };

  // Fetch product entitlements
  useEffect(() => {
    if (cartData && customerNumber && selectedBranch) {
      validateEntitlement();
    }
  }, [cartData, customerNumber, selectedBranch]);

  const checkEntitlement = item => {
    const entitlementForItem = entitlements.find(
      prod => prod.sku === item._item[0]._code[0].code
    );
    return entitlementForItem ? entitlementForItem.entitled : false;
  };

  const toggleActiveGroup = groupId => {
    const group = activeGroups.find(activeGroup => activeGroup === groupId);
    const updatedGroups = group
      ? activeGroups.filter(activeGroup => activeGroup !== groupId)
      : [...activeGroups, groupId];
    setActiveGroups(updatedGroups);
  };

  const groupCartProductsQuantityBySku = () => {
    if (cartData) {
      const uniqueSkus = Array.from(
        new Set(cartData.map(sku => sku._item[0]._code[0].code))
      );

      const uniqueProductsArray = [];
      uniqueSkus.forEach((uniqueSku, index) => {
        uniqueProductsArray.push({
          sku: uniqueSku,
          qtyEntered: 0,
          qtyAvailable: 0,
          name: "",
          brand: ""
        });

        cartData.forEach(item => {
          if (item._item[0]._code[0].code === uniqueSku) {
            uniqueProductsArray[index].qtyEntered += item.quantity;
            uniqueProductsArray[index].name =
              item._item[0]._definition[0]["display-name"];
            uniqueProductsArray[
              index
            ].brand = item._item[0]._definition[0].details.find(
              detail => detail.name === "brand"
            ).value;
          }
          if (item.branchAvailability) {
            uniqueProductsArray[index].qtyAvailable = item.branchAvailability;
          }
        });
      });
      return uniqueProductsArray;
    }
    return [];
  };

  const closeModal = () => {
    setIsModalOpened(false);
  };

  const openModal = () => {
    setIsModalOpened(true);
  };

  const skusArray =
    cartData && cartData.map(({ _item }) => _item[0]._code[0].code);

  const renderAlternateBranchesModal = () => {
    const styles = {
      modal: {
        maxWidth: "1280px",
        width: "100%",
        height: "650px"
      }
    };

    return (
      <Modal
        open={isModalOpened}
        onClose={closeModal}
        styles={{
          modal: styles.modal
        }}
      >
        <div>
          <AlternateBranchList
            orderInfo={{
              orderName,
              orderItemsLength: new Set(
                cartData.map(cartItem => cartItem._item[0]._code[0].code)
              ).size
            }}
            products={groupCartProductsQuantityBySku()}
            qtyColumnHeader={intl.get("stock-status")}
            history={history}
            branches={branchesList}
            availaibilityDisplayedWithoutQuantity
            skusArray={skusArray}
            savedOrderCartUri={addToDefaultCartLink}
            isSavedOrderDetails
          />
        </div>
      </Modal>
    );
  };

  const renderWarningMessage = () => {
    const hasError =
      savedOrder &&
      savedOrder.messages[0] &&
      savedOrder.messages[0].type === "warning";

    if (hasError) {
      return (
        <Messagecontainer
          message={{
            type: "danger-message",
            debugMessages: intl.get("price-calculation-error"),
            isMsgEmphasized: true
          }}
          closeContainerHandler={null}
          stayOpen
          button={{
            text: intl.get("support"),
            action: openSalesforceChat,
            className: "support-btn"
          }}
        />
      );
    }
    if (inventoryError) {
      return (
        <Messagecontainer
          message={{
            type: "basic",
            debugMessages: inventoryError
          }}
          closeContainerHandler={null}
        />
      );
    }
    return null;
  };

  const isLoadingOrderForPickup = loadingPickupOrDelivery === "pickup";

  const isLoadingOrderForDelivery = loadingPickupOrDelivery === "delivery";

  const isLoadingOrderToCart =
    isLoadingOrderForPickup || isLoadingOrderForDelivery;

  const isDisabledAddOrderForPickup =
    hasSpecialAirPurifiers ||
    removeItemDisabled ||
    isLoadingOrderForDelivery ||
    (isProductBeingAdded && isProductBeingAdded.sku !== "");

  const isDisabledAddOrderForDelivery =
    removeItemDisabled ||
    isLoadingOrderForPickup ||
    (isProductBeingAdded && isProductBeingAdded.sku !== "");

  if (loading) return <div className="loader" />;

  return (
    <>
      <div className="saved-order-details content-box content-table">
        {savedOrder && cartData ? renderAlternateBranchesModal() : null}
        <h2 className={`saved-order-title ${isMotili ? "" : ""}`}>
          <NavLink to="/myAccount/savedOrders" className="back-button">
            <span>{intl.get("back")}</span>
          </NavLink>
          <span className="title">{orderName}</span>
          {!isVirtualBranchUser && (
            <button
              type="button"
              className="availability-button"
              onClick={openModal}
            >
              <i className="icon-home" />
              {intl.get("check-order-availability")}
            </button>
          )}
        </h2>

        <p className="cart-error-message">{invalidSkuError}</p>
        {renderWarningMessage()}

        {cartData && cartData.length ? (
          <div className="cards-headers table-labels">
            <div className="card-header-product label">
              {`${intl.get("product")}${isMotili ? ":" : ""}`}
            </div>
            {config.calculatePrice && (
              <div className="card-header-price label">{intl.get("price")}</div>
            )}
            <div className="card-header-quantity label">
              {`${intl.get("quantity")}${isMotili ? ":" : ""}`}
            </div>
            <div className="card-header-availability label">
              {intl.get("availability")}
            </div>
            {config.calculatePrice && (
              <div className="card-header-ext-price label">
                {intl.get("ext-price")}
              </div>
            )}

            <div className="card-header-add-to-cart label">
              {intl.get("add-to-cart")}
            </div>
          </div>
        ) : (
          <span>{intl.get("there-are-no-saved-items-to-display")}</span>
        )}
        {cartData && cartData.length && entitlements
          ? Object.keys(groupedItems)
              .reverse()
              .map(group =>
                groupedItems[group].map((item, index) => (
                  <SavedOrderDetailComponent
                    prosItems={groupedItems[group]}
                    cartDetail={item}
                    cartId={cartId}
                    key={`${item._item[0]._code[0].code} ${group || ""} ${
                      item["shipping-method"]
                    } ${item["branch-number"]}`}
                    setIsChangeDisabled={disableChangesFromItem}
                    qtyChangeDisabled={qtyChangeDisabled}
                    removeItemDisabled={removeItemDisabled}
                    refreshCartDetails={getSavedOrderDetails}
                    hasError={item._item[0]._code[0].code === invalidSku}
                    groupId={group}
                    first={index === 0}
                    handleGroupItemsUpdate={handleGroupItemsUpdate}
                    activePros={activeGroups}
                    toggleActivePros={toggleActiveGroup}
                    handleAddSingleItemTypeToCart={
                      handleAddSingleItemTypeToCart
                    }
                    inventoryError={inventoryError}
                    isProductBeingAdded={isProductBeingAdded}
                    isLoadingOrderToCart={isLoadingOrderToCart}
                    entitled={checkEntitlement(item)}
                  />
                ))
              )
          : null}
      </div>
      {orderSummary && cartData && cartData.length ? (
        <div className="saved-order-details-add-to-cart-box">
          {config.calculatePrice && (
            <div>
              <span className="saved-order-details-total-label">
                {intl.get("todays-subtotal").toUpperCase()}:
              </span>
              <span className="saved-order-details-total-value">
                {orderSummary.subTotal.display}
              </span>
            </div>
          )}
          {config.calculatePrice && (
            <div className="saved-order-details-total">
              <span className="saved-order-details-total-label">
                {intl.get("estimated-total").toUpperCase()}:
              </span>
              <span className="saved-order-details-total-value">
                {orderSummary.total.display}
              </span>
            </div>
          )}
          <div className="pickup-delivery-buttons">
            {!isLoadingOrderForPickup ? (
              <button
                className="dast-btn dast-btn-primary"
                aria-label={intl.get("add-all-as-pick-up")}
                type="button"
                disabled={isDisabledAddOrderForPickup}
                onClick={() => {
                  handleAddToCart("pickup");
                }}
              >
                {intl.get("add-all-as-pick-up")}
              </button>
            ) : (
              <div className="miniLoader" />
            )}
            {!isLoadingOrderForDelivery ? (
              <button
                className="dast-btn dast-btn-primary"
                aria-label={intl.get("add-all-as-delivery")}
                type="button"
                disabled={isDisabledAddOrderForDelivery}
                onClick={() => {
                  handleAddToCart("delivery");
                }}
              >
                {intl.get("add-all-as-delivery")}
              </button>
            ) : (
              <div className="miniLoader" />
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default SavedOrderDetails;
