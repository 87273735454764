import React, { useState, useEffect, useContext, FC } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import intl from "react-intl-universal";
import { Input, getConfig } from "@zilker/store-components";
import MessageContainer from "@zilker/store-components/src/MessageContainer/messagecontainer";
import { withRouter, RouteComponentProps } from "react-router-dom";
import contactUsFormValidor from "../validation/contactUsFormValidation";
import { MainContext } from "../contexts/MainContext";
import { checkTokensExpired, pushToMaintenace } from "../utils/helpers";

import "../theme/sharedClasses.less";
// import "./ContactUsPageSF.less";

interface inputForm {
  brand: string;
  email: string;
  name: string;
  phone: string;
  company: string;
  subject: string;
  message: string;
}

interface formErrors {
  email: string;
  name: string;
  subject: string;
  message: string;
  phone?: any;
  company?: string;
}

interface infoMessage {
  debugMessages: string;
  id: string;
  type: string;
}

const ContactUsPageSF: FC<RouteComponentProps> = ({ history }) => {
  const context = useContext<{ auth: any; user: any; account: any }>(
    MainContext
  );
  const { config } = getConfig();
  const {
    cortexApi: { scope },
    displayRecaptcha,
    contactUsFormUrl
  } = config;
  const brand = scope.toUpperCase();

  const {
    user,
    auth: { logout },
    account
  } = context;
  const {
    userProfile: { givenName, familyName, phone, email }
  } = user;
  const {
    accountDetails: { customerNumber, company }
  } = account;

  // TODO - adjust options to new SF options
  //   const subjectOptions = [
  //     { key: "", value: intl.get("select-subject") },
  //     { key: "account_registration", value: intl.get("account-registration") },
  //     {
  //       key: "credentials_inquiry_support",
  //       value: intl.get("credentials-inquiry-support")
  //     },
  //     {
  //       key: "can_not_find_products",
  //       value: intl.get("can-not-find-products")
  //     },
  //     {
  //       key: "technical_website_issues",
  //       value: intl.get("technical-website-issues")
  //     },
  //     {
  //       key: "technical_product_help",
  //       value: intl.get("technical-product-help")
  //     },
  //     {
  //       key: "commercial_equipment_support",
  //       value: intl.get("commercial-equipment-support")
  //     },
  //     {
  //       key: "parts_and_supplies_support",
  //       value: intl.get("parts-and-supplies-support")
  //     },
  //     {
  //       key: "suggestions_site_feedback",
  //       value: intl.get("suggestions-site-feedback")
  //     },
  //     { key: "general_inquiry", value: intl.get("general-inquiry") }
  //   ];

  //   const inputForm: inputForm = {
  //     brand,
  //     email: user.userProfile.email,
  //     name: `${user.userProfile.givenName} ${user.userProfile.familyName}`,
  //     phone: `${user.userProfile.phone}`,
  //     company: account.accountDetails.company
  //       ? `${account.accountDetails.company} - ${account.accountDetails.customerNumber}`
  //       : "",
  //     subject: "",
  //     message: ""
  //   };

  //   const errorsInForm: formErrors = {
  //     email: "",
  //     name: "",
  //     subject: "",
  //     message: ""
  //   };

  //   const infoMessage: infoMessage = {
  //     debugMessages: "",
  //     id: "",
  //     type: ""
  //   };

  const inputForm: inputForm = {
    brand,
    email: user.userProfile.email,
    name: `${user.userProfile.givenName} ${user.userProfile.familyName}`,
    phone: `${user.userProfile.phone}`,
    company: account.accountDetails.company
      ? `${account.accountDetails.company} - ${account.accountDetails.customerNumber}`
      : "",
    subject: "",
    message: ""
  };

  const errorsInForm: formErrors = {
    email: "",
    name: "",
    subject: "",
    message: ""
  };

  const infoMessage: infoMessage = {
    debugMessages: "",
    id: "",
    type: ""
  };

  const [form, setForm] = useState(inputForm);
  const [formErrors, setErrors] = useState(errorsInForm);
  const [infoMsg, setInfoMsg] = useState(infoMessage);

  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    setForm(prevState => {
      const updatedState = { ...form };
      updatedState.name = `${givenName} ${familyName}`;
      updatedState.email = email;
      updatedState.phone = phone;
      updatedState.company = company ? `${company} - ${customerNumber}` : "";
      return { ...prevState, ...updatedState };
    });
  }, [givenName, familyName, email, company, customerNumber]);

  function inputHandler(e) {
    e.persist();
    setForm(prevState => {
      const updatedState = { ...form };
      updatedState[e.target.name] = e.target.value;
      return { ...prevState, ...updatedState };
    });
    setErrors(errorsInForm);
  }

  // TODO - implement modal when user successful submit is done
  function closeMessageContainer() {
    setInfoMsg(infoMessage);
  }

  function isGuestUser() {
    return email === "";
  }

  const handleRecaptchaChange = value => {
    setRecaptchaValue(value);
  };
  function httpPostFunction(url: string, data: any, options = {}) {
    return axios({ method: "post", url, data, ...options });
  }

  const sendContactUsSFForm = async body => {
    const url = contactUsFormUrl;
    try {
      const response = await httpPostFunction(url, body);

      return response;
    } catch (err) {
      return err;
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    const { errors, isValid } = contactUsFormValidor(form);
    let msg: infoMessage;
    if (!isValid) {
      setErrors(errors);
      return;
    }
    if (recaptchaValue || !displayRecaptcha) {
      sendContactUsSFForm(form)
        .then(res => {
          msg = {
            debugMessages: "Message sent",
            id: "",
            type: "needinfo"
          };
          setInfoMsg(msg);
          setForm(inputForm);
        })
        .catch(e => {
          if (checkTokensExpired(e)) {
            logout().catch(err =>
              pushToMaintenace(history, {
                e: err,
                errIn: "Logout => sendContactUsSFForm => ContactUsPageSF.tsx"
              })
            );
          } else {
            pushToMaintenace(history, {
              e,
              errIn: "sendContactUsSFForm => ContactUsPageSF.tsx"
            });
          }
        });
      setFormSubmitted(true);
    } else {
      alert("Please tick the reCAPTCHA.");
    }
  };

  if (formSubmitted) {
    return (
      <div id="thankYouMessage">
        <h2>Thank you for contacting the Daikin Digital Support Team!</h2>
        <p>
          We have received your inquiry, and a member of our team will be in
          touch with you shortly.
        </p>
      </div>
    );
  }

  return (
    <div id="formContainer">
      <form autoComplete="off" onSubmit={handleSubmit}>
        <input
          type="hidden"
          name="captcha_settings"
          value='{"keyname":"DaikinConsumerLead","fallback":"true","orgId":"00DVA0000010433","ts":""}'
        />
        <input type="hidden" name="orgid" value="00DVA0000010433" />
        <input type="hidden" name="RecordTypeId" value="012VA000000Risz" />
        <input
          type="hidden"
          name="recordType"
          id="recordType"
          value="012VA000000Risz"
        />
        <p style={{ fontSize: "medium" }}>
          <b>Contact Us</b>
        </p>
        <hr />
        <table style={{ width: "800", border: "0" }}>
          <tbody>
            <tr style={{ width: "100%" }}>
              <td>
                <label htmlFor="email">*EMAIL</label>
                <input
                  id="email"
                  maxLength={80}
                  name="email"
                  size={20}
                  type="email"
                  onChange={inputHandler}
                  value={form.email}
                  required
                  className={`${
                    formErrors && formErrors.email
                      ? "validation-error-border"
                      : null
                  }`}
                  pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                />
                {formErrors && formErrors.email ? (
                  <p className="validation-error"> {formErrors.email}</p>
                ) : null}
              </td>
            </tr>
            <tr style={{ width: "100%" }}>
              <td>
                <label htmlFor="name">*NAME</label>
                <input
                  id="name"
                  maxLength={50}
                  name="name"
                  size={40}
                  type="text"
                  onChange={inputHandler}
                  value={form.name}
                  required
                  className={`${
                    formErrors && formErrors.name
                      ? "validation-error-border"
                      : null
                  }`}
                />
                {formErrors && formErrors.name ? (
                  <p className="validation-error"> {formErrors.name}</p>
                ) : null}
              </td>
            </tr>
            <tr style={{ width: "100%" }}>
              <td>
                <label htmlFor="phone">PHONE</label>
                <input
                  id="phone"
                  maxLength={40}
                  name="phone"
                  size={20}
                  type="telephone"
                  pattern="^\(\d{3}\)\s\d{3}-\d{4}$|^\d{3}-\d{3}-\d{4}$|^\d{3}\.\d{3}\.\d{4}$|^\d{10}$"
                  onChange={inputHandler}
                  value={form.phone}
                  className={`${
                    formErrors && formErrors.phone
                      ? "validation-error-border"
                      : null
                  }`}
                />
                {formErrors && formErrors.phone ? (
                  <p className="validation-error"> {formErrors.phone}</p>
                ) : null}
              </td>
            </tr>
            <tr style={{ width: "100%" }}>
              <td>
                <label htmlFor="00NVA000002kaaQ">COMPANY/ACCOUNT #:</label>
                <input
                  id="00NVA000002kaaQ"
                  maxLength={128}
                  name="company"
                  size={40}
                  type="text"
                  onChange={inputHandler}
                  value={form.company}
                  disabled={!isGuestUser()}
                  className={`${
                    formErrors && formErrors.company
                      ? "validation-error-border"
                      : null
                  }`}
                />
                {formErrors && formErrors.company ? (
                  <p className="validation-error"> {formErrors.company}</p>
                ) : null}
              </td>
            </tr>
            <tr style={{ width: "100%" }}>
              <td>
                <label htmlFor="subject">*SUBJECT</label>
                <select
                  name="subject"
                  id="subject"
                  defaultValue=""
                  required
                  className={
                    formErrors.subject ? "validation-error-border" : null
                  }
                  onChange={inputHandler}
                  value={form.subject ? form.subject : ""}
                >
                  <option value="" hidden disabled label="Select Subject" />
                  <option value="Account Registration">
                    Account Registration
                  </option>
                  <option value="Credentials Inquiry Support">
                    Credentials Inquiry Support
                  </option>
                  <option value="Technical Website Issues">
                    Technical Website Issues
                  </option>
                  <option value="Technical Product Help">
                    Technical Product Help
                  </option>
                  <option value="Commercial Equipment Support">
                    Commercial Equipment Support
                  </option>
                  <option value="Parts & Supplies Support">
                    Parts &amp; Supplies Support
                  </option>
                  <option value="Suggestions/Site Feedback">
                    Suggestions/Site Feedback
                  </option>
                  <option value="General Inquiry">General Inquiry</option>
                  <option value="Order Status">Order Status</option>
                  <option value="Warranty Inquiry">Warranty Inquiry</option>
                  <option value="I can't find the Products I am looking for ">
                    I can not find the Products I am looking for{" "}
                  </option>
                </select>
                <p className="validation-error">
                  {formErrors.subject && formErrors.subject}
                </p>
              </td>
            </tr>
            <tr style={{ width: "100%" }}>
              <td>
                <label htmlFor="message">*MESSAGE</label>
                <textarea
                  name="message"
                  required
                  onChange={inputHandler}
                  value={form.message}
                  className={
                    formErrors.message ? "validation-error-border" : null
                  }
                />
                <p className="validation-error">
                  {formErrors.message && formErrors.message}
                </p>
                <br />
              </td>
            </tr>
          </tbody>
        </table>
        <input type="hidden" id="external" name="external" value="1" />
        <br />
        <br />
        {displayRecaptcha && (
          <ReCAPTCHA
            sitekey="6LfL5iQqAAAAAGY16yEn6D5r002h2CcFsKV16POW"
            onChange={handleRecaptchaChange}
          />
        )}
        <input type="submit" value="Send" />
      </form>
    </div>
  );
};

export default withRouter(ContactUsPageSF);
